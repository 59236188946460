import { watch } from 'vue'
import { AuthModule } from '@tracktik/tt-authentication'

import { PENDO_API_KEY } from './constants'
import { stopPendoTracking, startPendoTracking, installPendo } from './utils'

/**
 * Initialize Pendo and watch for authentication changes to start/stop the Pendo analytics.
 *
 * Pendo Documentation :
 * https://agent.pendo.io/public/agent/
 */
export const initPendoManager = async (authModule: AuthModule) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Development environment, Pendo will not be installed.')

    return
  }

  await installPendo(PENDO_API_KEY)

  watch(
    () => authModule.isAuthenticated(),
    async (isAuthenticated) => {
      if (isAuthenticated) startPendoTracking(authModule)
      else stopPendoTracking()
    },
    { immediate: true },
  )
}
